:root {
  --scrollbar-color: #e2e8f0;
}

.list-view-container::-webkit-scrollbar-track {
  box-shadow: none;
  background: white;
}

.grid-view-container::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
}

.list-view-container::-webkit-scrollbar-thumb,
.grid-view-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
}
