:root {
  --scrollbar-color: #c3cfdc;
}

.grid-view-container::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
}

.grid-view-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
}
