:root {
  --typhography-highcontrast: #0f172a;
  --borders-border: #e2e8f0;
  --secondary-background: #0a21d3;
  --white: #fff;
}

.fc {
  height: calc(100dvh - 64px - 24px - 80px - 24px); /* 100% - header - padding - calendar header - padding */
}

.fc-event {
  overflow: hidden;
  margin-left: -2px !important;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.fc-event-main {
  padding: 0 !important;
}
.fc-event:hover {
  filter: brightness(0.97);
}
.fc-timegrid-slot.fc-timegrid-slot-lane::before {
  content: none !important;
}

.fc-day .fc-scrollgrid-sync-inner {
  padding: 12px;
}

.fc-daygrid-day-number {
  margin: 0 !important;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Customize Calendar */
.full-calendar .fc th {
  text-align: left;
  font-size: 14px;
}
.full-calendar .fc thead {
  height: 57px;
}

/* day inside the day cell */
.fc-daygrid-day-number {
  margin-top: 13px;
  margin-left: 13px;
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--typhography-highcontrast);
}

/* add style with blue background only today */
.fc .fc-day-today a.fc-daygrid-day-number {
  background-color: var(--secondary-background);
  border-radius: 100%;
  color: var(--white);
  width: -webkit-min-content;
  width: -moz-min-content;
  width: fit-content;
  text-align: center;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* remove the yellow background only today for the month view */
.fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

/* remove the yellow background only today for the week/day view */
.fc-timegrid .fc-day-today {
  background-color: transparent !important;
}

/* time grid */
.fc .fc-timegrid-slot {
  border: 1px solid var(--borders-border) !important;
  height: 62px !important;
}

/* labels below all-day  */
& .fc-timegrid-slot-label-cushion {
  font-size: 14px;
  font-weight: 400;
  color: var(--typhography-highcontrast);
  margin-right: 10px;
}

/* cell where there is the day */
.fc .fc-daygrid-day-top {
  display: flex !important;
  flex-direction: row !important;
}

.fc-timegrid-slot.fc-timegrid-slot-label {
  padding: 1rem !important;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  text-transform: uppercase !important;
}
.fc .fc-scrollgrid-section > * {
  border: none !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-col-header-cell.fc-resource {
  align-content: center;
  padding: 16px !important;
}

.fc-timegrid-col-events {
  margin: 0px !important;
}

/* 
 TODO apply border-collapse unset manually to trigger default "double" border on table.
 Start overriding this component classes to match the figma design
 
 */
/*  
.fc * {
  border-collapse: unset !important;
}

.fc-scrollgrid,
.fc-scrollgrid table,
.fc-scrollgrid td,
.fc-scrollgrid th,
.fc-timegrid-slot-lane,
.fc-timegrid-axis,
.fc-resource,
.fc-col-header-cell {
  border: none !important;
}


.fc-scrollgrid,
.fc-scrollgrid table,
.fc-scrollgrid td,
.fc-scrollgrid th {
  border: 1px solid #ddd !important;
  border-spacing: 5px !important;
} */

/* .table.tr.th.td {
  border: 2px solid var(--borders-border) !important;
  border-spacing: 2px !important;
}

.table.thead.tr.th {
  border: 2px solid var(--borders-border) !important;
  border-spacing: 5px !important;
} */

/* .fc-col-header-cell.fc-resource {
  border: 1px solid #ddd !important;
  border-spacing: 5px !important;
}  */
