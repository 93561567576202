/* Remove default clear button (x) in search inputs in WebKit browsers (Chrome, Safari) */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none;
}

/* Remove default clear button (x) in search inputs in IE and Edge */
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* Remove default clear button (x) in search inputs in Firefox */
input[type='search']::-moz-clear {
  display: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
