@import './tailwind.css';
@import './list-view.css';
@import './grid-view.css';
@import './poppover.css';
@import './input-search.css';
@import './calendar.css';

* {
  margin: 0;
  padding: 0;
}

.mt-\[12px\] {
  margin-top: 12px !important;
}

.p-\[5px\] {
  padding: 5px !important;
}

.white {
  background-color: white;
}

.edit-job-box > span {
  display: block !important;
  margin: auto !important;
}

.dangerouslySetInnerHTML ol,
.dangerouslySetInnerHTML ul,
.dangerouslySetInnerHTML main {
  list-style: inside;
}

.dangerouslySetInnerHTML span.descrip * {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #696974 !important;
}

.dangerouslySetInnerHTML * {
  font-family: inherit !important;
}

.rdw-link-modal {
  height: auto !important;
}

.sidebar {
  z-index: 1;
}

.notes-Editor .rdw-editor-toolbar {
  margin-bottom: 0px;
}

.main-inr-copy-input-field > .copy-div {
  width: 10% !important;
  padding: 5px !important;
  border-right: 1px solid #d6d4d2 !important;
  border-radius: 0 2.5px 2.5px 0 !important;
  cursor: pointer !important;
  height: 31px !important;
  border-left: 0;
  border-top: 1px solid #d6d4d2 !important;
  border-bottom: 1px solid #d6d4d2 !important;
}

.button-css {
  line-height: 18px;
  font-size: 14px;
  font-family: poppins;
  padding-left: 50px;
  padding-right: 50px;
}

text-\[\#FAFAFB\] {
  color: #fafafb;
}

.dollar {
  display: block;
  position: relative;
}

.dollar input {
  padding-left: 45px;
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: '\f0c9';
}

.dollar:before {
  position: absolute;
  content: '$';
  left: 12px;
  top: 0;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  border-right: 1px solid #bfbfbf;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.pl-\[3px\] {
  padding-left: 3px !important;
}

.absolute-center {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
}

.absolute-right-top {
  position: absolute;
  top: 0;
  right: 0;
}

.main-only-left-icon::after {
  content: '';
  background-image: url('images/Icon12.svg');
  position: absolute;
  width: 30px;
  height: 22px;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-top: 5px;
  background-size: 60%;
}

.coming-50 {
  width: 45%;
  float: left;
}

.coming-55 {
  width: 55%;
  float: left;
}

.coming-50.background-blue {
  background-color: #0020d3;
  height: 100vh;
}

.coming-soon {
  margin-left: 10%;
  margin-top: 5%;
}

.border-\[\#D6D4D2\] {
  --tw-border-opacity: 1;
  border-color: #d6d4d2;
}

.coming-soon .form-soon {
  margin-top: 70px;
  color: white;
  width: 75%;
}

.ques-group {
  float: left;
  width: 100%;
  position: relative;
}

.ques-input {
  width: 70%;
  float: left;
}

.floatWidthFull {
  float: left;
  width: 100%;
}

.ques-remove-button {
  float: right;
  width: fit-content;
  position: absolute;
  transform: translate(10px, 10px);
  right: 0;
}

.listing .lg\:h-3\/4 {
  height: 55% !important;
}

.form-soon .mb-5 {
  margin-top: 50px;
  color: white;
}

.form-soon .leading-tight {
  line-height: 0.25;
}

.form-soon button {
  background: white !important;
  border-radius: 0.25rem;
  margin-top: 10px;
  color: #0a21d3;
  font-weight: bold;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.coming-soon .form-soon p.intro-text {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}

/* .coming-soon .caption {
  font-size: 50px;
  color: white;
  font-weight: 800;
  line-height: 60px;
} */

.coming-55.background-img > span {
  left: auto !important;
  width: 55% !important;
  text-align: right;
}

.coming-55.background-img img {
  object-fit: cover !important;
}

.main-only-left-icon1 {
  color: #0a21d3 !important;
}

.main-only-left-icon::after {
  content: '';
  background-image: url('images/Icon12.svg');
  position: absolute;
  width: 30px;
  height: 22px;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-top: 5px;
  background-size: 60%;
}

#top-search-location {
  border-color: rgb(114 116 130 / var(--tw-border-opacity)) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  padding-right: 0.5rem !important;
  padding-left: 3rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

#top-search-location:focus {
  border: 2px solid #0a21d3 !important;
}

.text-indigo-600 {
  color: #0a21d3;
}

.mainQuestionIcon:hover {
  background-color: #0a21d3;
}

.main-only-left-icon1 {
  color: #0a21d3 !important;
}

.onyl-kk {
  background: #0a21d3 !important;
}

.onyl-kk ul {
  background: #0a21d3 !important;
}

@media screen and (max-width: 500px) {
  .pipe_line_box .ony-width-thirty {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  select.listing-srcinput {
    text-indent: 54px;
    padding-left: 0;
  }
  .ony-width-thirty {
    margin-top: 15px !important;
  }
  .ony-width-seventy {
    margin-top: 15px !important;
  }
}

@media (max-width: 1024px) {
  div#__next .seeker-profile-image > span img {
    width: 100% !important;
    height: 100% !important;
  }

  .flex_dip {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .main-only-for-this {
    display: block !important;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1500px;
  }

  .shirnk-1250.container {
    max-width: 1250px !important;
  }

  .public-home .container {
    max-width: 1400px;
  }
}

.mtleft {
  margin-left: 22px;
}

.col3 {
  width: 45%;
  float: left;
  margin-right: 5%;
}

.shadowDiv {
  float: left;
  width: 100%;
}

.mainQuestionIcon {
  background-color: #0a21d3;
}

button.bg-green-500 {
  color: white;
}

.form-checkbox,
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  color: currentColor;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  height: 1.4em;
  width: 1.4em;
}

.form-checkbox {
  border-radius: 0.25rem;
}

.lg\:overflow-y-auto {
  overflow: auto;
}

.listing .lg\:overflow-y-scroll::-webkit-scrollbar-track {
  background: #d5d3d3;
  border-radius: 10px;
}

.listing .lg\:overflow-y-scroll::-webkit-scrollbar {
  width: 20px;
}

.listing .lg\:overflow-y-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #918f8f;
}

.align-center {
  align-items: center;
}

.listing .mb-9 {
  margin-bottom: 1rem;
}

select.select-sort {
  background-image: url('/images/Icon1.svg');
  background-position: right 0.75rem center;
  background-size: 14px 7px;
  padding: 10px 45px 10px 19px;
}

.public-home .klk {
  position: absolute;
}

.absolute-not input.focus\:outline-blue-600:focus,
.absolute-not select.focus\:outline-blue-600:focus {
  outline: none;
  outline-offset: none;
  border: 2px solid #0a21d3;
}

input[type='search'].focus\:outline-none:focus {
  outline: 2px solid #0a21d3 !important;
}

.listing .flex.items-flex-start.justify-end.w-full span {
  margin: 0px 0px 0px 9px !important;
  display: inline-block !important;
}

.form-radio {
  border-radius: 50%;
}

/* .flex {
  display: flex;
} */

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.post-submit {
  margin-top: 20px;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

span#basic-addon2 {
  position: absolute;
  top: 9px;
}

.main-of-the-pt-text {
  padding: 90px 0 150px !important;
}

input#google::placeholder {
  color: red;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}

.only-for-google img {
  position: absolute;
  margin-top: 5px;
  margin-left: 15px;
}

@media (max-width: 1024px) {
  .only-desktop-show {
    display: none !important;
  }

  .FormSteps_card2__z4peo {
    position: inherit !important;
    left: 0;
    padding: 10px 0;
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }

  .FormSteps_card2__z4peo h6 {
    margin-right: 30px;
  }
}

.PhoneInputInput:focus,
.PhoneInputInput:focus-visible {
  border-color: #fff !important;
  outline: #fff;
}

.card-form-input {
  border: 1px solid #e2e2e2;
  padding: 15px 10px;
  min-height: 49px;
  border-radius: 5px;
  margin-bottom: 15px;
}

footer .bg-indigo-700 {
  background-color: #010c61;
}

.main-of-the-pt-text-publichome {
  padding: 800px 0 150px !important;
}

.Jobseekers.items-center img,
.public-home .border-2 {
  --tw-border-opacity: 1;
  padding: 0 !important;
  border-radius: 0;
}

.colorB5B5BE {
  color: #b5b5be;
}

.color171725 {
  color: #171725;
}

.pay-button {
  background: #0056b2 !important;
  padding: 10px !important;
  color: #fff;
  width: 30%;
  margin: 0 auto;
  max-width: 100% !important;
  height: auto !important;
}

.listing .md\:pr-8 {
  padding-right: 1rem !important;
}

.pay-button:hover {
  color: #ffffff !important;
}

.stripe-card-element-from form {
  display: inline-block !important;
  width: 100%;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: #d6d4d2;
}

.stripe-card-element-from p.text-danger.invalid-error {
  font-style: unset !important;
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 12px;
}

.bg-indigo-700 {
  background-color: #0a21d3;
}

.stripe-card-element-from p.card-error {
  font-style: unset !important;
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 0;
}

.plan_selected {
  background: #003d94;
  color: #fff;
}

/* input.pac-target-input {
  width: 100%;
  border: 1px solid rgb(229, 231, 235);
  padding: 1rem 0.75rem;
  border-radius: 7px;
} */

.listing a.font-bold.text-xl.mr-5 {
  display: none;
}

.listing .con {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 7px;
  font-size: 14px;
  color: #44444f;
  font-weight: 500;
}

.listing .content span {
  background-color: #eee;
  padding: 4px;
  font-size: 12px;
}

.highlightOption {
  background-color: white !important;
  color: #000 !important;
}

.listing .content #multiselectContainerReact span,
#multiselectContainerReact li:hover {
  color: white !important;
  background-color: #0a21d3;
  background: #0a21d3 !important;
}

/* .listing .word-wrap-des {
  word-break: break-all !important;
} */

.listing .con input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.listing .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.listing .con:hover input ~ .checkmark {
  background-color: #ccc;
}

.listing .con input:checked ~ .checkmark {
  background-color: #3dd598;
}

.listing .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.listing .con input:checked ~ .checkmark:after {
  display: block;
}

.listing .con .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.listing .accordion-container {
  position: relative;
  height: auto;
  margin: 0 auto 10px;
}

.listing .accordion-container > h2 {
  text-align: center;
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.listing .set {
  position: relative;
  width: 100%;
  height: auto;
}

.listing .set > a {
  padding: 0 0 20px 0;
  text-decoration: none;
  color: #44444f;
  font-weight: 600;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 16px;
}

.handshake > span {
  height: 200px !important;
  width: 200px !important;
  top: 50% !important;
  z-index: 99 !important;
  left: -100px !important;
  padding: 44px !important;
  transform: translateY(-39%) !important;
  background: #e5f2ff !important;
  border-radius: 50% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  position: absolute !important;
}

.NoEvent {
  pointer-events: none;
}

.srch-arrow::after {
  position: absolute;
  content: '';
  background-image: url(./images/arrow.png);
  top: 23px;
  width: 23px;
  height: 10px;
  right: 3px;
  background-repeat: no-repeat;
  background-color: #fff;
}

.signup-image img {
  object-fit: cover;
}

.handshake img {
  padding: 2rem !important;
}

.rightsideimage > span {
  height: 100% !important;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.handshake span > span {
  display: none !important;
}

.listing .set > a i {
  float: right;
  margin-top: 2px;
}

.listing .content {
  background-color: #fff;
}

.listing .content p {
  padding: 10px 15px;
  margin: 0;
  color: #333;
}

.listing .only-kk {
  background: #e8e8e8;
}

.listing .capitalize {
  text-transform: capitalize;
}

.listing .display-text-group {
  font-size: 14px;
  color: #696974;
  font-weight: 400;
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
}

.min-height-jobpreview {
  min-height: 500px;
}

.listing h6.job-title-loc {
  align-items: center;
}

.btn-b {
  background-color: #000865 !important;
}

.listing .klk input {
  font-size: 16px;
}

.public-image-border {
  border: 2px solid #47609a !important;
  border-radius: 54px;
  padding: 12.5px;
}

.public-image-border > span {
  display: block !important;
}

.listing .tbx h1 {
  font-size: 24px;
}

.listing .tbx h6 {
  font-size: 16px;
  align-items: center;
}

.listing .bg-\[\#ccc\] {
  background-color: #f1f1f5;
}

.listing .tbx p {
  font-size: 14px;
  font-weight: bold;
  color: #696974;
  line-height: 1.4rem;
  font-family: 'poppins';
}

.listing .tbx .disc {
  font-weight: 400;
}

.listing .tbx a {
  font-size: 10px;
}

.listing .tbx button {
  font-size: 12px;
  border-radius: 10px;
}

.MuiSlider-thumb,
.MuiSlider-thumb span,
.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  background-color: blue !important;
}

.jobSearch .shadow-inner.pl-5.border-r {
  border: none;
}

.jobSearch .w-full.shadow-inner {
  background: #ffffff;
  height: 100%;
}

.listing-srcinput {
  appearance: button;
}

.text-\[\#000D3D\] {
  color: rgb(0 13 61 / var(--tw-text-opacity));
}

.border-[\#727482] {
  --tw-border-opacity: 1;
  border-color: rgb(114 116 130 / var(--tw-border-opacity));
}

.p-\[10px\] {
  padding: 10px;
}

nav a.signup-login.active,
nav a.signup-login:hover {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: rgb(10 33 211 / var(--tw-bg-opacity));
}

.jobSearch .bg-white.shadow {
  border-radius: 10px;
  box-shadow: none !important;
}

.jobbox .text-center.mb-4 {
  display: flex;
  align-items: center;
}

.jobbox .text-center.mb-4 h4.text-left {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 600;
}

.jobbox .text-center.mb-4 h4.text-left span {
  width: 100% !important;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.jobbox .w-full.bb {
  display: flex;
  flex-wrap: wrap;
}

.jobbox a.mr-2.bg-slate-200.py-1.px-3.rounded.lg\:text-xs.capitalize {
  height: 100%;
  display: flex;
  align-items: center;
}

.border-b-4 {
  border-bottom-width: 2px;
}

/* .hub-page .sidebar a>span {
  display: block;
  margin: auto;
  width: fit-content;
} */

.hub-page .up-f svg {
  pointer-events: none;
  cursor: pointer;
}

.hub-page .up-f input {
  padding: 20px;
  width: 100px;
  margin: auto;
  opacity: 0;
  cursor: pointer;
}

.contact-template .shadow-lg {
  margin: 0px auto 80px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 22%);
  border-radius: 5px;
  padding: 60px 60px;
  max-width: 560px;
}

.contact-template textarea#message {
  border-radius: 3px;
  border: 1px solid #e5e7eb;
  width: 100%;
  height: 144px;
}

section.contact-bg {
  background-image: url(./images/contact_background.jpeg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  padding: 20px;
}

.show {
  display: block;
}

.contact-template .form-group .md\:w-1\/2 {
  width: 48%;
}

.ab-vertical-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.opacity-low {
  opacity: 0.5;
  pointer-events: none;
}

.bg-\[\#0A21D3\] {
  --tw-bg-opacity: 1;
  background-color: #0a21d3;
}

.shadow-md {
  --tw-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.whitespace-pre {
  white-space: pre;
}

.px-2\.5 {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 1024px) {
  .listing .cont {
    margin: 0px auto;
    font-size: 15px;
    color: #565656;
    line-height: 22px;
  }

  .listing .box_wrapper {
    padding: 25px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    text-align: center;
  }

  .listing .box_wrapper:after {
    content: '';
    display: block;
    clear: both;
  }

  .listing .modal_box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }

  .listing .modal_box:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .listing .modal_content {
    max-width: 600px;
    margin: 0px auto;
    background: #ffffff;
    /* margin-top: 50px; */
    border: 1px solid #dddddd;

    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    opacity: 0;
    max-height: 90%;
    overflow-y: scroll;
  }

  .listing .modal_header,
  .listing .modal_footer,
  .listing .modal_body {
    padding: 15px;
    color: #565656;
  }

  .listing .modal_header {
    border-bottom: 1px solid #dddddd;
  }

  .listing .modal_footer {
    text-align: right;
    border-top: 1px solid #dddddd;
  }

  .listing .modal_footer a {
    display: inline-block;
    padding: 10px 20px;
    background: skyblue;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
  }

  .listing .modal_body {
    font-size: 14px;
    line-height: 22px;
  }

  .listing .modal_box.active .modal_content {
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  .listing .modal_box.active {
    opacity: 1;
    visibility: visible;
    padding: 10px;
  }

  .listing .modal_open {
    display: inline-block;
    background: #0a21d3;
    padding: 10px 40px;
    color: #ffffff;
    font-size: 20px;
    border-radius: 12px;
    font-weight: 600;
  }
}

nav .duration-1000 {
  transition-duration: 1000ms;
}

nav .transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

nav .hidden_2 {
  background: #fff;
}

@media (min-width: 1199px) {
  .mb {
    display: none !important;
  }
}

@media (max-width: 767px) {
  div[data-testid='flowbite-tooltip'] {
    width: 100%;
    position: absolute !important;
    left: 0 !important;
    max-width: 250px;
  }

  .tooltip .tooltiptext {
    width: 250px;
    margin-left: 0;
    left: -130px;
  }

  .seeker-profile-image > span {
    position: relative !important;
    width: 200px !important;
    margin: 0 auto !important;
    height: 200px !important;
  }

  .main-inner-all-cards .w-\[285px\] {
    margin-right: 10px;
  }

  .pb-\[20px\].main-inner-all-cards {
    display: -webkit-inline-box;
  }

  .profile_edit img {
    width: 80px !important;
  }

  .header-2 button.signup-login > span {
    width: auto !important;
  }

  .rightsideimage > span {
    height: 100% !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .handshake > span {
    display: none !important;
  }

  .container {
    width: 100%;
  }

  nav .hidden_2 {
    top: -150vh;
    z-index: 9999;
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
  }

  nav a.navigation.active,
  nav a.about.active {
    border: 0px;
    color: #0a21d3;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    background-color: rgb(229 242 255 / var(--tw-bg-opacity));
  }

  nav a.navigation:hover,
  nav a.about:hover {
    color: #0a21d3 !important;
    background-color: transparent !important;
  }

  .flex_2 {
    border-bottom: 2px solid #d6d4d2;
  }
}

nav a.navigation.active,
nav a.about.active {
  border-color: #0a21d3;
  color: #0a21d3;
  border-bottom-width: 6px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
}

nav a.navigation:hover,
nav a.about:hover {
  color: #0a21d3;
  background-color: rgb(229 242 255 / var(--tw-bg-opacity));
}

.flex_2 {
  display: flex;
  z-index: 9999;
  position: absolute;
  top: 48px;
  background: #fff;
  width: 100%;
  left: 0;
}

.font-12 {
  font-size: 12px;
}

.color-9D9D9D {
  color: #9d9d9d;
}

.bg-F7F9FE {
  background-color: #f7f9fe;
}

.rounded-5px {
  border-radius: 5px;
}

.items-center.bnr_fut.relative span {
  width: 100% !important;
}

.items-center.bnr_fut.relative img.bnnr {
  object-fit: cover;
}

.hub-job-box,
.relative {
  position: relative;
}

.hub-job-box .fa-box {
  top: 0;
  position: absolute;
  right: 0;
  color: rgb(0 18 152 / var(--tw-bg-opacity));
}

.mr-26 {
  margin-right: 26px;
}

::-webkit-scrollbar,
.str-chat__list::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track,
.str-chat__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb,
.str-chat__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b4b4b7;
}

.for_scroll::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
}

.for_scroll::-webkit-scrollbar-thumb {
  background: transparent;
  background-image: linear-gradient(transparent, #d9d9d9, transparent);
}

.demographics .image > span,
.experience-seeker .main-inner-date > span {
  margin-top: 5px !important;
  margin-left: 30px !important;
}

.experience-seeker .main-inner-date span:first-child,
.education-seeker .main-inner-date span:first-child,
.volunteerEx-seeker .main-inner-date span:first-child,
.skills-seeker .main-inner-date span:first-child {
  margin-left: 15px !important;
}

.min-height-500 {
  min-height: 500px;
}

.bg-\[\#003D94\] {
  background-color: rgb(0 61 148 / var(--tw-text-opacity));
}

.form-select {
  -moz-padding-start: calc(0.75rem - 3px) !important;
  background-image: url(./images/Icon1.svg) !important;

  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  background-repeat: no-repeat !important;
}

/* .jobSeeker-signup h4 {
  margin-top: 20px !important;
  margin-bottom: 8px !important;
} */
button.signup-login {
  position: relative;
}

.mr-left-right {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

button.signup-login > span {
  position: absolute !important;
  width: 100% !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.w-52px {
  width: 14%;
}

.profile-modal .main-inner-date > div,
.main-inner-ha .main-inner-date > div {
  background: #000865;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  width: 331px;
  justify-content: center;
  border-radius: 5px;

  top: 0;
  pointer-events: none;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
}

.profile-seeker .image > span {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  max-width: fit-content !important;
}

.display-none {
  display: none;
}

.block-span > span {
  display: block !important;
}

.z-index {
  z-index: 99999;
}

.object-cover {
  object-fit: cover;
}

.border-radius-50 > span {
  border-radius: 50%;
}

.image-span-35 > span {
  width: 35px !important;
  height: 35px !important;
  margin: auto !important;
  display: block !important;
}

.bg-000865 {
  background-color: #000865;
}

.company_department input {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: rgb(55 65 81 / var(--tw-text-opacity));
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  outline: none;
}

._3tcyg {
  width: 100% !important;
  height: auto !important;
}

.seeker-profile-image > span img {
  width: 230px !important;
  height: 188px !important;
  position: relative !important;
  object-fit: cover;
}

.seeker-profile-image > span {
  position: relative !important;
}

.image-span-35.profile > span {
  width: 30px !important;
  height: 30px !important;
}

.account-settings-steps ul#FormSteps_progressbar__5Efzk li:nth-child(3):after,
.account-settings-steps ul#FormSteps_progressbar__5Efzk li:nth-child(4):after {
  top: 146px;
  left: 19px;
  height: 160px;
}

.account-settings-steps ul#FormSteps_progressbar__5Efzk li:nth-child(5):after {
  top: 290px;
  left: 19px;
  height: 80px;
}

.bg-whiteSmoke {
  background-color: whitesmoke;
}

.width-100px {
  width: 100px;
}

.baner-sec h2 i.fa.fa-pencil::before {
  color: #000;
}

.about_culture .abt h4 input,
.about_culture .cult h4 input {
  width: 100%;
  border: 1px solid #e4e4e4;
}

.about_culture .abt h2 input,
.about_culture .cult h2 input {
  border: 1px solid #e4e4e4;
}

.about_culture .abt h4 i.fa.fa-pencil {
  line-height: 36px;
  font-size: 18px;
}

.thin-border-D6D4D2 {
  border: 1px solid #d6d4d2;
}

.seeker-profile .toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.seeker-profile .toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.seeker-profile .toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.seeker-profile .toggle-switch .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.seeker-profile .toggle-switch input:checked + .slider {
  background-color: #5ac424;
}

.seeker-profile .toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px #5ac424;
}

.seeker-profile .toggle-switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.seeker-profile .toggle-switch .slider.round {
  border-radius: 34px;
}

.seeker-profile .toggle-switch .slider.round:before {
  border-radius: 50%;
}

@media print {
  .not-print {
    display: none;
  }

  @page {
    size: portrait;
  }

  .print-block {
    display: block;
    float: left;
    width: 20%;
  }

  .print-block-25 {
    display: block;
    float: left;
    width: 25%;
  }

  .print-w-full {
    width: 100%;
    float: left;
    display: block;
  }

  .page-break-after.break-after-avoid-page {
    page-break-after: always;
  }

  .main-inner-back-of-this-pdf-layer {
    background: #f2f2f2;
    height: 200vh;
  }

  br.main-pdf-br-tag-none {
    display: block !important;
  }

  .seeker-profile-image > span {
    margin: 0 auto 0 0 !important;
  }

  .main-of-thi-only-pdf-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
  }

  .main-of-thi-only-pdf-image .seeker-profile-image > span {
    width: 84px !important;
    height: 70px !important;
  }

  .main-of-thi-only-pdf-image .seeker-profile-image > span .seeker-profile-image > span img {
    width: 100% !important;
    height: 100% !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 14px 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #5ac424;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/*------radio button -----*/

.seeker-profile .tain {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.seeker-profile .tain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.seeker-profile .mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.seeker-profile .tain input ~ .mark {
  background-color: #0a21d3;
}

.seeker-profile .mark:after {
  content: '';
  position: absolute;
  display: none;
}

.seeker-profile .tain input ~ .mark:after {
  display: block;
}

.seeker-profile .tain .mark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* --------radio button css ------*/
.tooltip {
  position: relative;
  display: inline-block;
}

.round.tooltip {
  display: flex;
}

.tooltip > span:not(.tooltiptext) {
  margin-left: 3px !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #fff;
  color: #000;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-left: -60px;
  padding: 10px;
  border: 1px solid #ddd;
}

.tooltip .uploadCandidate.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #fff;
  color: #000;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: auto;
  margin-left: 3px;
  padding: 10px;
  bottom: 0px;
  border: 1px solid #ddd;
}

.tooltip .round.tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: #000;
  text-align: left;
  border-radius: 3px;
  top: 10px;
  position: absolute;
  margin-left: 0px;
  z-index: 1;
  left: 210px;
  padding: 0;
  border: 1px solid #ddd;
}

.pac-container {
  background-color: #fff;
  z-index: 2001;
  position: fixed;
  display: inline-block;
}

.profile-modal {
  z-index: 2000;
}

.border-c1bcbc {
  border-color: #c1bcbc;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.profile-modal .bg-white,
.modal .bg-white {
  background: white;
}

.profile-modal .text-\[\#000D3D\],
.modal .text-\[\#000D3D\] {
  color: rgb(0 13 61 / var(--tw-text-opacity)) !important;
}

.profile-modal .bg-transparent,
.modal .bg-transparent {
  background: transparent !important;
}

/*------ tooltip--------*/
/* The Modal (background) */

.display-text-group .displayed-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.read-more-button {
  font-size: 13px;
}

.only-desktop-show.search-nav input:focus {
  border: 2px solid #0a21d3 !important;
  outline: none !important;
}

.only-desktop-show.search-nav input {
  border: 2px solid #f2f5fa !important;
  outline: none !important;
}

.single-job-des {
  height: 400px;
  padding-right: 15px;
}

.main-inr-btn div {
  width: 100%;
  text-align: center;
  margin: 0 auto !important;
  padding: 18px;
  cursor: pointer;
  border: 1px solid #0020d3;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: #000865;
}

label.sc-bczRLJ {
  background: hsl(221deg 90% 88%);
  border-radius: 50%;
  height: 188px;
  border: none;
  color: black;
  min-width: 100%;
}

.main-inner-color {
  background: #e5e5e5;
}

.main-inner-data-flow {
  height: 700px;
}

.main-inr-main {
  margin: 0 !important;
}

.main-inr-drop {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 523px);
}

.emp-hub-img > span {
  display: block !important;
}

.main-inner-all-cards::before {
  position: absolute;
  left: -40px;
  height: 98%;
  content: '';
  display: block;
  top: 13px;
  width: 40px;
  background-color: #fafafc;
  box-shadow:
    0 80px 0 var(--ds-shadow-overflow-spread, #091e4229),
    5px -2px 7px var(--ds-shadow-overflow-perimeter, #091e421f);
  clip-path: inset(1px -13px 0px 0px);
}

.main-inner-all-cards::after {
  position: absolute;
  right: -40px;
  height: 98%;
  content: '';
  display: block;
  top: 13px;
  width: 40px;
  background-color: #fafafc;
  box-shadow:
    0 80px 0 var(--ds-shadow-overflow-spread, #091e4229),
    -5px -2px 7px var(--ds-shadow-overflow-perimeter, #091e421f);
  clip-path: inset(1px 0px 0px -13px);
}
.main-inner-cards:hover {
  overflow-x: scroll;
}
.main-inner-cards {
  overflow: hidden;
  height: 34rem;
}
.main-inner-cards::-webkit-scrollbar {
  background: transparent;
  background-color: transparent;
  transition: all 0.3s;
}
.main-inner-cards::-webkit-scrollbar-track,
.main-inner-cards::-webkit-scrollbar-track:hover {
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  cursor: grab;
  transition: all 0.3s;
}

.scroll-container {
  overflow: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.menu-candidate-view-details-scroll {
  overflow-y: auto;
  height: 100%;
}

.menu-candidate-view-details-scroll::-webkit-scrollbar {
  width: 8px;
  transition: all 0.3s;
}

.menu-candidate-view-details-scroll::-webkit-scrollbar-track {
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  cursor: grab;
  transition: all 0.3s;
}

.menu-candidate-view-details-scroll::-webkit-scrollbar-thumb {
  background: #e2e8f0;
  border-radius: 8px;
}

.css-1d8n9bt,
.css-ackcql {
  padding-left: 0 !important;
}
.main-inner-cards::-webkit-scrollbar {
  height: 10px;
}
.main-inner-cards::-webkit-scrollbar-thumb {
  background: #ddd;
}
/* .main-inner-cards::-webkit-scrollbar-track:hover .main-inner-cards::-webkit-scrollbar-thumb {
  background: #ddd;
} */
@media (min-width: 1024px) {
  .emp-hub-tabing-jk {
    width: calc(100% - 6rem) !important;
    margin-left: auto;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  footer {
    z-index: 1;
    position: relative;
  }
}

@media (max-width: 1500px) {
  .all-selects {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .only-for-resp-div,
  .ony-width-thirty {
    width: 100% !important;
  }

  .pipe_line_box .only-for-resp-div {
    margin-right: 0;
  }

  .pipe_line_box .ony-width-thirty {
    display: flex;
    width: 100% !important;
  }

  .main-inner-all-cards {
    display: flex;
    width: 1500px;
    padding-bottom: 0;
  }

  .main-overscroll {
    max-width: 1500px;
    overflow-x: auto !important;
    width: 100%;
    overflow: hidden;
  }

  .ony-width-seventy {
    width: 70% !important;
  }

  .ony-width-thirty {
    width: 29% !important;
  }
  .full-full {
    width: 100% !important;
  }
  .as-f.dk {
    flex-wrap: nowrap;
  }
  .as-f.dk button {
    font-size: 16px;
    padding: 14px 40px;
  }
}

.rmsc .dropdown-container {
  border: none !important;
}

.dropdown-container .dropdown-heading {
  display: none !important;
}

div[data-testid='flowbite-tooltip'] {
  background: transparent !important;
  border: none;
  box-shadow: none;
}

div[data-testid='flowbite-tooltip'] > div,
div[data-testid='flowbite-tooltip'] > div > span,
div[data-testid='flowbite-tooltip'] > div > ul {
  padding: 0px !important;
}

div[data-testid='flowbite-tooltip'] > div > ul li {
  padding-top: 0px !important;
}

div[data-testid='flowbite-tooltip'].invisible .dropdown-container {
  pointer-events: none !important;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
}

div[data-testid='flowbite-tooltip-target'] button:focus {
  box-shadow: none !important;
}

div[data-testid='flowbite-tooltip-target'] button {
  background: transparent;
  color: #000;
  font-weight: 700;
}

div[data-testid='flowbite-tooltip-target'] button span.flex.items-center.rounded-md.text-sm.px-4.py-2 {
  padding: 0;
  font-size: 16px;
}

div[data-testid='flowbite-tooltip-target'] button:hover {
  background: transparent;
  color: #000;
}

div[data-testid='flowbite-tooltip'] {
  width: 300px;
}

.rmsc.multi-select {
  width: 100%;
}

.accordion-item {
  margin-bottom: 10px;
}

.upload-candidate .all-inputs-colors input,
.upload-candidate .all-inputs-colors textarea,
.upload-candidate .all-inputs-colors select {
  font-size: 14px;
}

.upload-candidate .all-inputs-colors input::placeholder {
  color: #000d3d !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
}

.upload-candidate .all-inputs-colors textarea::placeholder {
  font-size: 16px;
  font-style: italic;
}

.main-inr-copy-input-field > .copy-div {
  width: 10% !important;
  padding: 5px !important;
  border-right: 1px solid #d6d4d2 !important;
  border-radius: 0 2.5px 2.5px 0 !important;
  cursor: pointer !important;
  height: 31px !important;
  border-left: 0;
  border-top: 1px solid #d6d4d2 !important;
  border-bottom: 1px solid #d6d4d2 !important;
  display: flex;
  align-items: center;
}

/* .fixed-notes {
  position: fixed;
  top: 30%;
  width: calc(100% - 15.5rem);
  transform: translateY(-50%);
  margin: 0 auto;
} */

.profile-job-text-limited {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

/*-------new-------*/

.col2 {
  width: 45%;
  float: left;
  margin-right: 5%;
}

.jobName-2line {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  width: fit-content;
}

.jobSearch .w-full.shadow-inner {
  background: #fff;
  height: 60px;
  appearance: none;
}

.main-inner-upload {
  position: relative;
}

.main-inner-upload input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 8px;
  z-index: 999;
  opacity: 0;
  cursor: pointer;
}

.primary-inner-upload input {
  position: absolute;
  width: 140px;
  /* height: 140px; */
  left: 8px;
  z-index: 999;
  opacity: 0;
  cursor: pointer;
}

/* .main-inner-upload span {
  position: relative;
  background: #001298;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  margin-left: 0;
  left: 8px;
  cursor: pointer;
  pointer-events: none;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #ffffff;
  width: 200px;
  cursor: pointer;
} */

.primary-inner-upload span {
  position: relative;
  background: #fff;
  /* padding: 10px 0px; */
  border-radius: 3px;
  margin: auto;
  /* left: 8px; */
  cursor: pointer;
  pointer-events: none;
  font-weight: 700;
  font-family: mulish !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #001298;
  width: 140px;
  cursor: pointer;
  border-color: #001298;
  border-width: 1px;
  padding: 4px;
}

.jobSearch .w-full.shadow-inner {
  background: #fff;
  height: 60px;
  appearance: none;
}

.listing-srcinput svg {
  top: 50%;
  transform: translateY(-50%);
}

.flex_dip {
  position: fixed;
  width: 85%;
  bottom: 10px;
  left: 130px;
  right: 0;
  background-color: #fff;
  margin: auto;
  padding: 20px 0;
}

.w-fit[data-testid='flowbite-tooltip-target'] > button,
.w-fit[data-testid='flowbite-tooltip-target'] {
  width: 100%;
}

.str-chat__channel-preview-messenger--active {
  border-radius: 0 !important;
}

.header-title {
  padding: 5px 7px;
}

.header-pound {
  color: #0a21d3;
  font-weight: 800;
  padding-right: 2px;
}

.str-chat__message-simple-text-inner {
  max-width: 100% !important;
  padding: 14px !important;
}

.str-chat__message-simple-text-inner p {
  line-height: 1.5 !important;
  font-size: 15px;
}

.message-header-name,
.message-header-timestamp > time {
  font-size: 17px !important;
}

.header-item {
  font-size: 20px;
}

.str-chat *,
.str-chat *::after,
.str-chat *::before {
  font-family: poppins !important;
}

.str-chat__li {
  float: left !important;
  width: 100% !important;
  padding: 10px !important;
}

.str-chat__message-simple-name,
.str-chat__message-simple-timestamp {
  font-weight: normal !important;
  font-size: 14px !important;
}

.pinned-message .str-chat__message-simple-text-inner {
  background: #c3e3e0 !important;
  font-style: italic !important;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
}

.channel-search__result-user {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.result-hashtag {
  margin: 12px;
  display: flex;
  justify-content: center;
  font-family:
    Helvetica Neue,
    sans-serif;
  font-weight: bold;
  color: #005fff;
}

.channel-search__result-text {
  width: 100%;
  font-family:
    Helvetica Neue,
    sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #2c2c30;
}

.str-chat__channel-search input {
  font-size: 15px !important;
}

.str-chat__channel-search input {
  background-color: white !important;
}

.str-chat__channel-preview-messenger {
  padding: 10px !important;
  margin: 0px !important;
}

.user-online {
  color: red;
  font-size: 10px;
  padding-top: 2px;
  padding-left: 5px;
}

.channel-search-header {
  font-weight: 700;
  font-size: 16px;
}

.str-chat__channel-search-container.popup {
  z-index: 999 !important;
}

.str-chat__header-livestream {
  border-radius: 0 !important;
}

.str-chat__main-panel {
  padding: 0 !important;
}

.str-chat__avatar-fallback {
  font-size: inherit !important;
}

.str-chat__channel-search,
.str-chat-channel-list {
  background-color: var(--white-smoke) !important;
}

.str-chat.commerce,
.str-chat.messaging {
  background: var(--white-smoke) !important;
}

.str-chat__container {
  height: 100%;
  display: flex;
  width: 80%;
  margin: auto;
}

button.str-chat__channel-preview-messenger[aria-selected='true'] {
  background-color: var(--white-smoke) !important;
}

.str-chat-channel-list {
  width: 18% !important;
}

/*--------------------pdf css-------------*/
br.main-pdf-br-tag-none {
  display: none;
}

textarea[data-testid='message-input'] {
  display: flex !important;
  line-height: inherit !important;
}

.messaging.str-chat .str-chat__header-livestream {
  box-shadow: none !important;
  border-bottom: 2px solid #d6d4d2;
}

.str-chat__li {
  margin: 0;
  padding: 15px;
  position: relative;
}

.message-wrapper {
  display: flex;
}

.message-header {
  display: flex;
}

.str-chat__li:hover {
  background-color: #e9e9ea !important;
  cursor: pointer;
}

.str-chat__message-actions-box {
  left: 0 !important;
}

/* .str-chat__li:hover .str-chat__message-simple__actions {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  border-radius: 100px;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
  z-index: 1;
  padding: 0 4px 0;

} */
.message-wrapper-content .str-chat__message-simple__actions {
  display: none !important;
}

.str-chat__li--single {
  margin: 0px !important;
}

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
  border: none !important;
  padding: 0 0 0px 0 !important;
  background-color: transparent !important;
}

.str-chat__simple-reactions-list {
  margin-top: 0px !important;
  margin-left: 5px !important;
}

.str-chat__message-simple__actions {
  margin-top: 0;
}

.str-chat__message-actions-box {
  top: initial;
  left: -100px;
  border-radius: 16px 16px 0 16px;
}

.str-chat__message-text {
  display: flex;
  width: 100%;
}

.str-chat__message-text-inner {
  position: relative;
  flex: 1;
  display: block;
  min-height: 32px;
  font-size: 15px;
  color: black;
  border-radius: 0;
  border: 0;
  margin-left: 0;
}

li.str-chat__li:hover .str-chat__message-simple__actions,
.message-wrapper.my-4 .str-chat__message-simple__actions:hover {
  width: auto;
  float: left;
}

.str-chat__square-button,
.str-chat__thread-start {
  border: none !important;
  background-color: transparent !important;
}

.messaging.str-chat .str-chat__thread {
  min-width: 450px;
  max-width: 450px;
  margin: 0 !important;
  border-radius: 0 !important;
}

.str-chat__square-button,
.str-chat__thread-start {
  margin: 0 !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0px !important;
}

ul.str-chat__ul li {
  margin: 0 !important;
}

.str-chat__thread-header {
  box-shadow: none !important;
  height: 75px !important;
  min-height: 75px !important;
  border-bottom: 2px solid #d6d4d2 !important;
}

li.str-chat__li:hover .str-chat__message-simple__actions,
.message-wrapper.my-4 .str-chat__message-simple__actions:hover {
  display: flex !important;
}

.message-wrapper.my-4 .str-chat__message-simple__actions {
  display: none !important;
}

.message-wrapper-content .str-chat__message-simple__actions > div,
.message-wrapper-content .str-chat__message-simple__actions > button {
  display: flex !important;
}

.str-chat__message-replies-count-button {
  font-family:
    Helvetica Neue,
    sans-serif;
  font-weight: bold;
  font-size: 13px;
  text-align: right;
  color: #4e1d9d;
}

.str-chat__simple-reactions-list {
  border-radius: 10px;
}

.str-chat__reaction-selector {
  max-height: 40px;
  top: -58px;
  right: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #bed5e4;
}

.str-chat__message-reactions-list-item__count {
  color: #000000;
}

.str-chat__message-simple-status {
  right: 22px;
}

.quoted-message-inner {
  margin-top: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.str-chat__li .quoted-message.mine .quoted-message-inner {
  background: #ebebeb;
}

.react-tags__search-input,
.css-1s2u09g-control {
  width: 100% !important;
  border: 1px solid #d6d4d2 !important;
  font-size: 14px;
  padding: 2px 6px;
  color: rgb(114 116 130 / var(--tw-text-opacity));
  border-radius: 2.5px;
  height: 26px;
}

.rc-time-picker-input {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.react-calendar {
  border: 0 !important;
  font-family: poppins !important;
}

.react-calendar abbr {
  font-size: 12px;
}

.calendar .searchWrapper {
  border: 0 !important;
}

.calendar .optionContainer {
  border: none !important;
}

.calendar .optionListContainer {
  left: 0 !important;
}

.calendar.multiSelectContainer input {
  margin-top: 0 !important;
  min-height: 30px !important;
}

.calendar .optionContainer li.option {
  display: flex;
  align-items: center;
}

.calendar.multiSelectContainer ul.optionContainer {
  max-height: 100% !important;
  overflow: hidden !important;
  border-radius: 0px !important;
}

.calendar .searchWrapper .chip {
  white-space: break-spaces !important;
}

.MuiTooltip-tooltip {
  font-family: mulish !important;
  padding: 28px !important;
  background: white !important;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.bg-\[\#fff\].p-0.m-0.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  background: transparent !important;
}

._3tcyg > div {
  position: absolute;
  margin-top: 5px;
  z-index: 99999;
  background-color: white;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  top: inherit !important;
  max-height: max-content !important;
}

._3tcyg > div > ul li {
  padding: 6px;
  font-size: 13px;
  cursor: pointer;
}

._3tcyg > input {
  width: inherit !important;
}

._3tcyg > div > ul {
  overflow-y: auto;
  max-height: 500px;
}

._3tcyg > div > ul::-webkit-scrollbar,
.str-chat__list::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}

/* Track */
._3tcyg > div > ul::-webkit-scrollbar-track,
.str-chat__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
  background: #d9d9d9;
}

/* Handle */
._3tcyg > div > ul::-webkit-scrollbar-thumb,
.str-chat__list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b4b4b7;
}

.react-tags__selected-tag {
  border: 1px solid #d9d9d9;
  border-radius: 2.5px;
  padding: 0px 9px;
  margin-right: 5px;
  background-color: #f3f1ef;
  display: inline-flex;
}

.react-tags__selected-tag > span {
  font-size: 14px;
  gap: 5px;
}
.save_global:hover {
  background: #e7f2fe;
}
.save_global {
  width: 320px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #001298;
  background: #fff;
  color: #001298;
  text-align: center;
  font-size: 16px;
  font-family: Mulish;
  font-weight: 600;
  letter-spacing: -0.32px;
  transition: all 0.3s;
}
.upload-candidate .css-319lph-ValueContainer {
  padding: 0 !important;
}
.upload-candidate .css-6j8wv5-Input {
  padding: 0 !important;
  margin: 0 !important;
}
#dropdownDividerButton:hover svg path {
  fill: #0a21d3;
}

.react-tags__selected-tag > .react-tags__selected-tag-name {
  display: inline-flex;
}

.react-calendar__month-view__weekdays__weekday abbr {
  visibility: hidden;
  display: block;
  width: 10px;
  margin: auto;
  text-decoration: auto;
}

.react-calendar__month-view__weekdays__weekday abbr::first-letter {
  visibility: visible !important;
  justify-content: center;
  text-align: center;
}

.react-calendar__month-view__days__day.react-calendar__tile--now,
.react-calendar__month-view__days__day.react-calendar__tile--active.react-calendar__tile--now {
  background: #0a21d3 !important;
  color: white !important;
  border-radius: 49%;
}

.HRsettings3 {
  display: none;
}

.react-calendar__month-view__days__day.react-calendar__tile--active {
  background: #e6e6e6 !important;
  color: black !important;
  border-radius: 49%;
}

.react-calendar__month-view__days__day.react-calendar__tile:enabled:hover,
.react-calendar__month-view__days__day.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 49%;
  /*flex-basis: 40px !important; */
}

.react-calendar__month-view__days__day.react-calendar__month-view__days {
  justify-content: flex-start;
}

/* .react-calendar__tile{
  border-radius: 49%;
  flex-basis: 40px !important;
} */

.react-calendar__navigation__label__labelText--from {
  font-size: 15px !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

button[title='CALENDAR SETTINGS'] {
  padding: 10px 60px 10px 25px !important;
}

button[title='CALENDAR SETTINGS']:after {
  background-image: url('/images/Gear.svg');
  width: 22px;
  height: 22px;
  content: '';
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.side-images-width {
  width: 26px;
}

.only-svg-white:hover path {
  stroke: #fff;
}

/* .active-tabs {
    background: #001298;
    color: #fff !important;
}
.active-tabs path {
  fill:#fff;
} */
.on-hover:hover path {
  fill: #0a21d3;
}
.on-hover-aspect:hover path {
  fill: #001298;
}
.on-hover-yes:hover path {
  fill: #35713a;
}
.on-hover-no:hover path {
  fill: #c63f3f;
}
.on-hover1:hover circle,
.on-hover1:hover ellipse,
.on-hover-no-stroke:hover circle,
.on-hover-no-stroke:hover ellipse {
  fill: #0a21d3;
}

.on-hover1:hover path {
  stroke: #0a21d3;
}

.on-hover1.active-tabs path,
.on-hover1.active-tabs svg,
.on-hover-no-stroke.active-tabs path,
.on-hover-no-stroke.active-tabs svg {
  stroke: white;
  fill: transparent;
}

.str-chat-channel {
  max-height: calc(100vh - 90px) !important;
}

.str-chat {
  height: calc(100vh - 90px) !important;
}

.str-chat.str-chat-channel-list.messaging.light {
  overflow: hidden;
  overflow-y: auto !important;
}

@media (min-width: 1921px) {
  .react-calendar__tile {
    padding: 10px 10px !important;
    height: 49px;
    width: 40px !important;
    flex-basis: 50px !important;
  }
}

.main-content-inner-select:before {
  content: 'Primary time zone';
  position: absolute;
  top: 8px;
  z-index: 9;
  font-size: 13px;
  left: 16px;
}

.main-content-inner-select > div {
  max-height: 55px;
}

.main-content-inner-select input {
  background: transparent !important;
}

.main-content-inner-select input:focus-visible {
  outline: none;
}

.floating-bar {
  padding-bottom: 300px;
}

.floating-bar .notranslate.public-DraftEditor-content {
  height: 37px;
  overflow-y: auto;
}

.fixed-notes {
  position: fixed;
  bottom: 0;
  width: calc(100% - 16rem) !important;
}

.rdw-editor-toolbar {
  background: #f9f9f9 !important;
  border-radius: 5px 5px 0 0 !important;
  margin-bottom: 0px !important;
}

.profile-modal .rdw-editor-toolbar {
  border: 1px solid #c1bcbc !important;
}

.profile-modal .rdw-editor-main {
  border-top: 0 !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.notes-Editor .rdw-editor-main {
  width: 100%;
  border-radius: 0;
  border: none;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin: 0;
  padding: 5px 10px;
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.rdw-option-wrapper {
  background: transparent !important;
  border: none !important;
}

.main-inr-popup-textarea-inr {
  background: #f7f9fe;
  border-radius: 0px 0 5px 5px;
}

.notranslate.public-DraftEditor-content::-webkit-scrollbar-track,
.notranslate.public-DraftEditor-content::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
  background: #ffffff;
}

.notranslate.public-DraftEditor-content::-webkit-scrollbar-thumb,
.notranslate.public-DraftEditor-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ffffff;
}

.smallmodal {
  position: relative;
}

.smallmodal:nth-last-child(1) {
  border-radius: 0 0 5px 5px;
}

.main-of-tooltip {
  display: none;
}

.smallmodal:hover .main-of-tooltip {
  display: block !important;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 10px !important;
}

.active-tabs .svg-back {
  background: #d0e7ff;
  color: #fff !important;
  border-radius: 14px;
}

.on-hover:hover .svg-back {
  background: #dadce0;
  border-radius: 14px;
}

.sidebar a.on-hover:hover path,
.sidebar .active-tabs a path {
  fill: #404040;
}

.active-tabs > span {
  font-weight: bold;
  color: black;
}

.sidebar a.on-hover:hover {
  font-weight: normal;
}

.svg-back {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tags__suggestions mark {
  background-color: #e7f2fe;
}

.react-tags__selected {
  margin-bottom: 3px;
  margin-top: 5px;
}

.react-tags__suggestions {
  background-color: white;
  width: 100%;
  border: 1px solid #d6d4d2;
  padding: 5px;
  border-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-width: 660px;
  width: 100%;
  z-index: 1;
}

.react-tags__suggestions * {
  font-size: 14px;
  color: #727482;
}

.str-chat__message-replies-count-button {
  margin-top: 0 !important;
}

.main-of-tooltip .h-\[78px\]:nth-last-child(1) {
  border: none !important;
  min-height: 79px;
}

.customTime .rc-time-picker-input {
  border-left: 1px solid #d9d9d9 !important;
  border-top-left-radius: 2.5px !important;
  border-bottom-left-radius: 2.5px !important;
}

.customTime .rc-time-picker-clear {
  height: auto !important;
  top: 0 !important;
}

span.rc-time-picker {
  height: 26px !important;
  overflow: hidden !important;
  display: flex !important;
  align-items: center !important;
}

.rc-time-picker-input {
  height: 26px !important;
  border-radius: 2.5px !important;
}

.main-inner-upload-one .truncate {
  font-size: 14px;
}

.rc-time-picker-panel-select ul {
  min-height: 144px !important;
}

li .item-renderer > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-range-slider * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.multi-range-slider {
  display: flex;
  position: relative;
  border: solid 1px gray;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 1px 1px 4px black;
  flex-direction: column;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge,*/
}

.multi-range-slider .bar {
  display: flex;
}

.multi-range-slider .bar-left {
  width: 25%;
  background-color: #f0f0f0;
  border-radius: 10px 0px 0px 10px;
  box-shadow: inset 0px 0px 5px black;
  padding: 4px 0px;
}

.multi-range-slider .bar-right {
  width: 25%;
  background-color: #f0f0f0;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset 0px 0px 5px black;
}

.multi-range-slider .bar-inner {
  background-color: lime;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: solid 1px black;
  justify-content: space-between;
  box-shadow: inset 0px 0px 5px black;
}

.multi-range-slider .bar-inner-left {
  width: 50%;
}

.multi-range-slider .bar-inner-right {
  width: 50%;
}

.multi-range-slider .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.multi-range-slider .thumb::before {
  content: '';
  background-color: inherit;
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 1px black;
  box-shadow:
    0px 0px 3px black,
    inset 0px 0px 5px gray;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
}

.multi-range-slider .input-type-range:focus + .thumb::after {
  content: '';
  position: absolute;
  top: -4px;
  left: -7px;
  width: 11px;
  height: 11px;
  z-index: 2;
  border-radius: 50%;
  border: dotted 1px black;
  box-shadow:
    0px 0px 5px white,
    inset 0px 0px 10px black;
}

.multi-range-slider .caption {
  position: absolute;
  bottom: 35px;
  width: 2px;
  height: 2px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  display: none;
}

.multi-range-slider .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  text-align: center;
  line-height: 30px;
  background-color: blue;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 0px 5px black;
  padding: 0px 5px;
  white-space: nowrap;
}

.multi-range-slider .thumb:active .caption {
  display: flex;
}

.multi-range-slider .input-type-range:focus + .thumb .caption {
  display: flex;
}

.multi-range-slider .input-type-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.multi-range-slider .ruler {
  margin: 10px 0px -5px 0px;
  display: flex;
  /* display: none; */
  overflow: hidden;
}

.multi-range-slider .ruler .ruler-rule {
  border-left: solid 1px;
  border-bottom: solid 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0px;
}

.multi-range-slider .ruler .ruler-rule:last-child {
  border-right: solid 1px;
}

.multi-range-slider .ruler .ruler-sub-rule {
  border-left: solid 1px;
  /* border-bottom: solid 1px; */
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 3px 0px;
  bottom: 0px;
  margin-bottom: -5px;
}

.multi-range-slider .ruler .ruler-sub-rule:first-child {
  border-left: none;
}

.multi-range-slider .labels {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: -20px;
  /* display: none; */
}

.multi-range-slider .label {
  font-size: 80%;
  display: flex;
  width: 1px;
  justify-content: center;
}

.multi-range-slider .label:first-child {
  justify-content: start;
}

.multi-range-slider .label:last-child {
  justify-content: end;
}

.multi-range-slider.zero-ranage-margin .thumb-left {
  right: 12px;
}

.multi-range-slider.zero-ranage-margin .thumb-right {
  left: 8px;
}

.multi-range-slider-black * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.multi-range-slider-black {
  display: flex;
  position: relative;
  background-color: black;
  border: solid 1px gray;
  padding: 30px 10px 25px 10px;
  flex-direction: column;
  color: white;
  -webkit-touch-callout: none;
  /* iOS Safari*/
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge,*/
}

.multi-range-slider-black .bar {
  display: flex;
}

.multi-range-slider-black .bar.active * {
  transition: none;
}

.multi-range-slider-black .bar-left {
  width: 25%;
  background-color: gray;
  border-radius: 10px 0px 0px 10px;
  box-shadow: inset 0px 0px 5px black;
  border: solid 1px black;
  padding: 5px 0px;
}

.multi-range-slider-black .bar-right {
  width: 25%;
  background-color: gray;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset 0px 0px 5px black;
  border: solid 1px black;
}

.multi-range-slider-black .bar-inner {
  background-color: lime;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: solid 1px black;
  justify-content: space-between;
  box-shadow: inset 0px 0px 5px black;
}

.multi-range-slider-black .bar-inner-left {
  width: 50%;
}

.multi-range-slider-black .bar-inner-right {
  width: 50%;
}

.multi-range-slider-black .thumb {
  background-color: white;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.multi-range-slider-black .thumb::before {
  content: '';
  background-color: inherit;
  position: absolute;
  width: 20px;
  height: 30px;
  border: solid 1px black;
  box-shadow:
    0px 0px 5px black,
    inset 0px 0px 10px gray;
  border-radius: 7px;
  z-index: 1;
  margin: -10px -12px;
  cursor: pointer;
}

.multi-range-slider-black .input-type-range:focus + .thumb::after {
  content: '';
  position: absolute;
  top: -6px;
  left: -8px;
  width: 12px;
  height: 22px;
  z-index: 2;
  border-radius: 7px;
  border: dotted 1px black;
  box-shadow:
    0px 0px 5px white,
    inset 0px 0px 10px black;
}

.multi-range-slider-black .caption {
  position: absolute;
  bottom: 45px;
  width: 2px;
  height: 2px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  /* display: none; */
}

.multi-range-slider-black .thumb .caption * {
  position: absolute;
  min-width: 30px;
  height: 30px;
  font-size: 75%;
  text-align: center;
  line-height: 30px;
  background-color: blue;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 0px 5px black;
  padding: 0px 5px;
  white-space: nowrap;
}

.multi-range-slider-black .thumb:active .caption {
  display: flex;
}

.multi-range-slider-black .input-type-range:focus + .thumb .caption {
  display: flex;
}

.multi-range-slider-black .input-type-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.multi-range-slider-black .ruler {
  margin: 10px 0px -5px 0px;
  display: flex;
  overflow: hidden;
}

.multi-range-slider-black .ruler .ruler-rule {
  border-left: solid 1px;
  border-bottom: solid 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0px;
}

.multi-range-slider-black .ruler .ruler-rule:last-child {
  border-right: solid 1px;
}

.multi-range-slider-black .ruler .ruler-sub-rule {
  border-left: solid 1px;
  /* border-bottom: solid 1px; */
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 3px 0px;
  bottom: 0px;
  margin-bottom: -5px;
}

.multi-range-slider-black .ruler .ruler-sub-rule:first-child {
  border-left: none;
}

.multi-range-slider-black .labels {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: -20px;
  /* display:none; */
}

.multi-range-slider-black .label {
  font-size: 80%;
}

.multi-range-slider-black.zero-ranage-margin .thumb-left {
  right: 12px;
}

.multi-range-slider-black.zero-ranage-margin .thumb-right {
  left: 8px;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border: 3px solid #fff !important;
}

.str-chat__channel-search {
  position: absolute !important;
  left: 50% !important;
  z-index: 999 !important;
  display: block !important;
  min-height: 200px !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  width: 350px !important;
}

.str-chat__channel-search-input {
  width: 100% !important;
}

.str-chat__channel-search-container.popup {
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

.str-chat__channel-search-container .str-chat__channel-search-result {
  display: block !important;
}

.str-chat__channel-search-result .str-chat__avatar {
  float: left !important;
}

.channel-preview__container {
  height: 56px;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
}

.channel-preview__container:hover {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.channel-preview__container.selected {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-name {
  font-family:
    Helvetica Neue,
    sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-message {
  font-family:
    Helvetica Neue,
    sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #858688;
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-inner-upload-one span {
  position: relative;
  background: #fff;
  color: #d6d4d2;
  padding: 0px 15px;
  border-radius: 2.5px;
  margin-left: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d6d4d2;
  height: 26px;
}

.sidebar svg path {
  fill: #737373;
}

.active-tabs svg path {
  fill: #404040;
}

.apexcharts-text tspan {
  font-family: poppins !important;
  font-size: 13px;
  z-index: 1;
}

.apexcharts-menu-icon {
  display: none;
}

.ui-slider .ui-btn-inner {
  padding: 4px 0 0 0 !important;
}

.ui-slider-popup {
  position: absolute !important;
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 36px;
  padding-top: 14px;
  z-index: 100;
  opacity: 0.8;
}

/* text {
  transform: rotate(0deg) translate(25px, 21px);
} */

.shadow-shimmer-card {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}

/*----- css N -----*/
/* main {
  padding-top: 3rem;
} */

.for_sl .css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer {
  padding: 3px;
}

.for_sl .css-1hb7zxy-IndicatorsContainer {
  height: max-content !important;
}

.for_sl .css-1s2u09g-control,
.for_sl .css-1pahdxg-control {
  min-height: 26px !important;
  padding: 0 6px !important;
}

.for_sl .css-14el2xx-placeholder {
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
}

.for_sl .css-1s2u09g-control,
.for_sl .css-1pahdxg-control {
  min-height: 20px !important;
  padding: 0 6px !important;
  font-size: 14px;
  height: 26px;
  max-height: 26px;
  border-radius: 2.5px;
}

.for_sl .css-319lph-ValueContainer {
  padding: 0 !important;
  position: relative;
}

.for_sl .css-319lph-ValueContainer:focus {
  top: -2px;
}

.for_sl .css-6j8wv5-Input {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 26px;
  height: 26px;
  padding-right: 0 !important;
}

.for_sl .css-qc6sy-singleValue {
  font-size: 13px;
}

.for_sl .css-1pahdxg-control .css-319lph-ValueContainer {
  top: 0;
}

.react-tags,
.react-tags__search {
  margin-top: -2px;
}

option {
  font-size: 14px;
}

/*---- 5/25 -----*/
.select-arrow {
  position: relative;
  display: block;
  height: 100%;
}

.select-arrow svg {
  position: absolute;
  right: 2px;
  top: 50%;
  background-color: #fff;
  pointer-events: none;
  transform: translateY(-50%);
  z-index: 1;
  width: 28px;
}

.select-arrow svg path {
  fill: #d6d4d2;
}

.for_sl .css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.for_sl .css-2613qy-menu > * {
  font-size: 14px !important;
}

ul#menu {
  height: 100%;
}

div#menuToggle input[type='checkbox'] {
  display: none;
}

.sidebar {
  top: 54px;
}

body ._3LDgJ {
  padding: 0;
  padding-left: 0 !important;
  border: 0;
}

footer {
  position: relative;
  z-index: 1;
}

@media (max-width: 1380px) {
  .emp-hub-tabing-jk {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 1024px) {
  .sidebar {
    flex-flow: column;
  }

  div#menuToggle input[type='checkbox'] {
    display: block;
  }

  .sidebar > a {
    display: block;
    width: 100%;
  }

  .emp-hub-tabing-jk {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100% !important;
    margin-left: auto;
  }

  .sidebar {
    flex-flow: column;
    width: 100%;
    justify-content: flex-start;
    top: 77px;
    padding: 50px 0;
  }
}

@media (max-width: 1024px) {
  .emp-hub-tabing-jk {
    margin-left: auto;
    padding: 50px 15px;
    margin-right: 0;
  }

  .emp-hub-tabing-jk .react-calendar {
    width: 100%;
  }

  .react-calendar abbr {
    font-size: 14px;
  }

  .react-calendar__month-view__days__day.react-calendar__tile {
    height: 6.7vw;
  }

  .react-calendar__navigation button {
    font-size: 38px;
  }

  /* 
  Set base font size to 14px using body instead of universal selector (*).
  Using * would force 14px on all elements, breaking Tailwind's text utility classes.
  Setting it on body allows proper inheritance while letting Tailwind utilities work as intended.
*/
  body {
    font-size: 14px;
  }

  #dropdownDividerButton svg {
    width: 14px;
  }

  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    margin-top: calc(0rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0rem * var(--tw-space-y-reverse));
  }

  /*------ menu here ------*/

  #menuToggle {
    display: block;
    position: relative;
    top: 42px;
    left: 0;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: 7px;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
    -webkit-touch-callout: none;
  }

  #menuToggle > span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 2;
    left: 10px;
    transform-origin: 4px 0;
    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  #menuToggle span,
  #menuToggle a {
    transform-origin: 4px 0;
    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(42deg) translate(-3px, -3px);
    background: #232323;
    position: fixed;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-10px, 10px);
  }

  #menu {
    position: fixed;
    width: 100%;
    margin: 0 0 0 -106px;
    padding: 0;
    padding-top: 0;
    background: transparent;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    height: 100vh;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    top: 0;
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
    margin: 0;
  }

  /*--- menu end ---*/
  /* main {
    padding-top: 3rem;
  } */
}

.header-2 {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.22));
}

.css-tlfecz-indicatorContainer {
  padding: 2px !important;
}

.css-1d8n9bt {
  min-height: 26px !important;
  height: 26px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-ackcql {
  min-height: 26px !important;
  height: 26px !important;
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (max-width: 1200px) {
  .dk {
    display: none !important;
  }
  /*---------- 24-june-2023----------*/
  label.list-new.dropdown.mb {
    display: inline-block;
    position: relative;
    margin-top: 15px;
    width: 100%;
  }

  .dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
  }

  .dd-button:after {
    content: '';
    position: relative;
    top: 50%;
    right: -14px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    z-index: 2;
  }

  .dd-input {
    display: none;
  }

  .dd-menu {
    position: relative;
    top: 100%;
    border: 0;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
  }

  .dd-input + .dd-menu {
    display: none;
  }

  .dd-input:checked + .dd-menu {
    display: block;
  }

  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }

  .dd-menu li:hover {
    background-color: #f6f6f6;
  }

  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }

  .dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
}

@media (max-width: 767px) {
  .react-calendar__tile {
    height: 13.45vw;
  }
  .emp-hub-tabing-jk .react-calendar {
    width: 320px;
    margin: auto;
  }
  .react-calendar__month-view__days__day.react-calendar__tile {
    height: 2.8rem !important;
  }
  span.rc-time-picker {
    width: 100%;
  }
  .t-c {
    text-align: center;
  }
  .for-r {
    overflow-x: auto;
  }
}

@media (max-width: 430px) {
  .react-calendar__month-view__days__day.react-calendar__tile {
    height: 13vw;
  }
}

#react-select-12-listbox > div > div:hover,
#react-select-12-listbox > div div:first-child:hover,
#react-select-9-listbox > div > div:hover,
#react-select-9-listbox > div div:first-child:hover,
#react-select-6-listbox > div > div:hover,
#react-select-6-listbox > div div:first-child:hover,
#react-select-17-listbox > div > div:hover,
#react-select-17-listbox > div div:first-child:hover,
#react-select-14-listbox > div > div:hover,
#react-select-14-listbox > div div:first-child:hover,
#react-select-11-listbox > div > div:hover,
#react-select-11-listbox > div div:first-child:hover,
#react-select-3-listbox > div > div:hover,
#react-select-3-listbox > div div:first-child:hover,
#react-select-7-listbox > div > div:hover,
#react-select-7-listbox > div div:first-child:hover {
  background-color: #deebff !important;
  color: black !important;
}

#react-select-6-listbox > div > div,
#react-select-12-listbox > div > div,
#react-select-17-listbox > div > div,
#react-select-3-listbox > div > div,
#react-select-9-listbox > div > div,
#react-select-7-listbox > div > div,
#react-select-11-listbox > div > div,
#react-select-14-listbox > div > div {
  background-color: transparent !important;
  color: black !important;
}

.rmsc .dropdown-content {
  padding-top: 0px !important;
}

.filter-shimmer-header > div {
  width: 100%;
}

.filter-shimmer-header .grid.shimmer-title.grid-gap-10.w-40 {
  margin-bottom: 15px !important;
}

.talent-profile-shimmer .shimmer-avatar {
  width: 150px !important;
  height: 150px !important;
}

.grid.shimmer-title.grid-gap-10 {
  width: 50%;
  margin-bottom: 0px !important;
}

.shimmer-avatar {
  margin-bottom: 0px !important;
  width: 60px !important;
  height: 60px !important;
}

.overview-button .shimmer-button {
  height: 32px !important;
  width: 100% !important;
  margin-bottom: 0px !important;
}

.analytic-button .shimmer.shimmer-button.shimmer-button--md {
  width: 100%;
  height: 49px !important;
  margin-bottom: 0px !important;
}

.main-inr-copy-input-field .shimmer-button {
  width: 100%;
  margin-bottom: 5px;
}

.analytic-cirle .shimmer-avatar {
  width: 220px !important;
  height: 220px !important;
}

.shimmer-text {
  width: 100%;
}

.bg-gmi {
  background-image: url(/images/bg-image.png);
}

.shimmer.shimmer-thumbnail.border-rounded {
  margin-bottom: 0px !important;
  float: left;
  width: 100%;
}

.emp-hub .shimmer-button {
  width: 100% !important;
  margin-bottom: 0px !important;
  height: 49px !important;
}

.editor-height .rdw-editor-main {
  height: 20rem !important;
}

.hover-path-001298:hover path {
  fill: #001298;
}

.sn-29lvy7 path {
  color: #87909f;
}

.sn-29lvy7 {
  height: 24px;
  --flex-y: initial;
  animation:
    250ms ease 0s 1 normal none running SpinnerAnimationShow,
    0.7s linear 0s infinite normal none running SpinnerAnimationRotation;
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transform-origin: 50% 50%;
  color: var(--jybopzu-color-neutral400);
}

​ .rule-l6mge0 {
  animation:
    250ms ease 0s 1 normal none running SpinnerAnimationShow,
    0.7s linear 0s infinite normal none running SpinnerAnimationRotation;
  transition-property: opacity, transform;
  transition-timing-function: ease;
  transform-origin: 50% 50%;
}

@keyframes SpinnerAnimationRotation {
  0% {
    transform: scaleX(-1) rotateZ(0deg);
  }

  50% {
    transform: scaleX(-1) rotateZ(-180deg);
  }

  100% {
    transform: scaleX(-1) rotateZ(-360deg);
  }
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 10px !important;
}

.react-calendar__century-view__decades__decade {
  font-size: 12px !important;
}

.react-calendar__decade-view__years__year {
  font-size: 13px !important;
}

.react-calendar__tile--hasActive,
.react-calendar__tile--now {
  background-color: transparent !important;
}

.react-calendar__tile--hasActive:hover,
.react-calendar__tile--now:not(react-calendar__month-view__days__day):hover {
  background-color: #f0f0f0 !important;
}

.on-message:hover path {
  fill: #0a21d3;
}
.job-listing .grid.shimmer-title.grid-gap-10 {
  margin-bottom: 10px !important;
}

.rdw-editor-main h1 * {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
  font-weight: bold;
}
.rdw-editor-main h2 * {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-weight: bold;
}
.rdw-editor-main h3 * {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: bold;
}
.rdw-editor-main h4 * {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.rdw-editor-main h5 * {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.rdw-editor-main h6 * {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 600;
}

.dangerouslySetInnerHTML h1 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h2 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h3 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h4 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.dangerouslySetInnerHTML h5 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.dangerouslySetInnerHTML h6 {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 600;
}

.dangerouslySetInnerHTML h1 {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h2 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h3 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: bold;
}
.dangerouslySetInnerHTML h4 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.dangerouslySetInnerHTML h5 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
  font-weight: 600;
}
.dangerouslySetInnerHTML h6 {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 600;
}
.display_none input {
  display: none;
}
/* .side-sticky {
  position: sticky!important;
  top: 100px;
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
} */

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

@media (min-width: 1024px) {
  .lg\:hidden-important {
    display: none !important;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
